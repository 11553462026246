import React, { useState } from 'react';
import env from "react-dotenv";
import { UploadOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Upload, message,ConfigProvider } from 'antd';
import './LogoUpload.css';

/**
 * Vérifie le type et la taille du fichier.
 * Retourne true si le fichier est valide, false sinon.
 */
const validateFile = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must be smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

const LogoUpload = ({ logoUrl}) => {
  
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  /**
   * Vérification avant l'upload :
   * - Si invalide, on ajoute le fichier en statut "error" et on annule l'upload.
   */
  const handleBeforeUpload = (file) => {
    const isValid = validateFile(file);
    if (!isValid) {
      // On force un unique fichier "erreur" dans fileList
      setFileList([
        {
          uid: file.uid,
          name: file.name,
          status: 'error',
        },
      ]);
      return Upload.LIST_IGNORE; // Annule l'upload
    }
    return true;
  };

  /**
   * Gère la mise à jour de la fileList selon les statuts.
   * - "uploading" : affiche la barre de progression
   * - "done" : affiche la miniature
   * - "error" : rend un affichage personnalisé (icône rouge) via itemRender
   */
  const handleChange = (info) => {
    console.log('Full info',info)
    // Ne conserve que le dernier fichier (assure un seul fichier en fileList)
    const newFileList = info.fileList.slice(-1);
    setFileList(newFileList);

    const { status, response, name } = info.file;
    if (status === 'uploading') {
      setLoading(true);
    }
    if (status === 'done') {
      setLoading(false);
      if (response && response.success) {
        // Transmet l'URL/chemin au composant parent
        logoUrl(response.path);
      }
    }
    if (status === 'error') {
      setLoading(false);
      message.error(`Upload of ${name} failed.`);
    }
  };

/**
   * Appelée lorsque l'utilisateur clique sur la corbeille pour supprimer un fichier.
   * On vide également l'état parent via logoUrl('').
   */
const handleRemove = (file) => {
  logoUrl('');
  return true; // Permet de retirer le fichier de la fileList
};


  return (
    <ConfigProvider
      theme={{
          components: {
              Button: {
                  colorPrimary: `#E16400`,
                  colorPrimaryHover: `#FFFFFF`,
                  colorPrimaryActive: `#E16400`,
                  lineWidth: 1,
                  defaultBg: `#EDF2F6`,
                  controlHeight: 60,
                  borderRadius: 9.38,
                  defaultBorderColor: `#E0E4EC`,
                  fontSize: 19.26,
                  diplays: 'flex',
                  justifyContent: 'center',
                  fontWeight: 500,
              },
              Upload: {
                  defaultBg: `#EDF2F6`,
                  defaultBorderColor: `#E0E4EC`,
                  defaultColor: `#E16400`,
                  borderRadius: 9.38,
                  fontSize: 19.26,
                  diplays: 'flex',
                  justifyContent: 'center',
                  fontWeight: 500,
              },
            }
      }}
    >
      <div className='up-cont' >
      <Upload
        name="image"
        listType="picture"
        action={env.REACT_APP_BACK_URL + '/uploadlogo'}
        fileList={fileList}
        multiple={false}    // Empêche la sélection multiple
        maxCount={1}        // Limite explicitement à 1 fichier
        beforeUpload={handleBeforeUpload}
        onChange={handleChange}
        onRemove={handleRemove}
        showUploadList={{
          showPreviewIcon: false,  // Pas d'icône d'aperçu
          showRemoveIcon: true,    // Icône corbeille pour supprimer
        }}
      >
        <Button
          className='gradient-button'
        
          icon={<UploadOutlined />}
          loading={loading} // Le bouton peut aussi afficher un spinner
        >
          {loading ? 'Uploading...' : 'Upload'}
        </Button>
      </Upload>
      </div>
    </ConfigProvider>

      
  );
};

export default LogoUpload;
