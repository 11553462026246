import React from 'react'
import './RoadMap.css'
export const RoadMap = ({ stepsCompleted, goToStep}) => {
  //console.log('roadmap',goToStep)
    return (
      <>
        <div className="roadmap">
          <div className="roadmap-flex">
            <div className={`shadow-circle ${stepsCompleted >= 1 ? 'active' : ''}`} onClick={() => goToStep(1)}>
              <div className={`roadmap-chip ${stepsCompleted >= 1 ? 'active' : ''} onit`}>Info</div>
              <p className={`point ${stepsCompleted >= 1 ? 'active' : ''}`}>1</p>
            </div>
            <div className={`shadow-circle ${stepsCompleted >= 2 ? 'active' : ''}`}>
              <div className={`roadmap-chip ${stepsCompleted >= 2 ? 'active' : ''}`}>Logo</div>
              <p className={`point ${stepsCompleted >= 2 ? 'active' : ''}`}>2</p>
            </div>
            <div className={`shadow-circle ${stepsCompleted >= 3 ? 'active' : ''}`}>
              <div className={`roadmap-chip ${stepsCompleted >= 3 ? 'active' : ''}`}>Contact</div>
              <p className={`point ${stepsCompleted >= 3 ? 'active' : ''}`}>3</p>
            </div>
            <div className={`shadow-circle ${stepsCompleted >= 4 ? 'active' : ''}`}>
              <div className={`roadmap-chip ${stepsCompleted >= 4 ? 'active' : ''}`}>Review</div>
              <p className={`point ${stepsCompleted >= 4 ? 'active' : ''}`}>4</p>
            </div>
            <div className={`shadow-circle ${stepsCompleted >= 5 ? 'active' : ''}`}>
              <div className={`roadmap-chip ${stepsCompleted >= 5 ? 'active' : ''} ${stepsCompleted === 5 ? 'bounce': ''}`}>Processing</div>
              <p className={`point ${stepsCompleted >= 5 ? 'active' : ''}`}>5</p>
            </div>
            <div className={`shadow-circle ${stepsCompleted >= 6 ? 'active' : ''}`}>
              <div className={`roadmap-chip ${stepsCompleted >= 6 ? 'active' : ''}`}>Result</div>
              <p className={`point ${stepsCompleted >= 6 ? 'active' : ''}`}>6</p>
            </div>
          </div>
        </div>
        <div className="roadmap-responsive-container">
          <div className="roadmap-responsive">
            <div className="roadmap-flex">
              <div className={`shadow-circle ${stepsCompleted >= 1 ? 'active' : ''}`} onClick={() => goToStep(1)}>
                <div className={`roadmap-chip ${stepsCompleted >= 1 ? 'active' : ''} onit`}>info</div>
                <p className={`point ${stepsCompleted >= 1 ? 'active' : ''}`}>1</p>
              </div>
              <div className={`shadow-circle ${stepsCompleted >= 2 ? 'active' : ''}`}>
                <div className={`roadmap-chip ${stepsCompleted >= 2 ? 'active' : ''}`}>logo</div>
                <p className={`point ${stepsCompleted >= 2 ? 'active' : ''}`}>2</p>
              </div>
              <div className={`shadow-circle ${stepsCompleted >= 3 ? 'active' : ''}`}>
                <div className={`roadmap-chip ${stepsCompleted >= 3 ? 'active' : ''}`}>Contact</div>
                <p className={`point ${stepsCompleted >= 3 ? 'active' : ''}`}>3</p>
              </div>
            </div>
          </div>
          <div className="roadmap-responsive">
            <div className="roadmap-flex">
              <div className={`shadow-circle ${stepsCompleted >= 4 ? 'active' : ''}`}>
                <div className={`roadmap-chip ${stepsCompleted >= 4 ? 'active' : ''}`}>Review</div>
                <p className={`point ${stepsCompleted >= 4 ? 'active' : ''}`}>4</p>
              </div>
              <div className={`shadow-circle ${stepsCompleted >= 5 ? 'active' : ''}`}>
                <div className={`roadmap-chip ${stepsCompleted >= 5 ? 'active' : ''} ${stepsCompleted === 5 ? 'bounce': ''}`}  >Processing</div>
                <p className={`point ${stepsCompleted >= 5 ? 'active' : ''}`}>5</p>
              </div>
              <div className={`shadow-circle ${stepsCompleted >= 6 ? 'active' : ''}`}>
                <div className={`roadmap-chip ${stepsCompleted >= 6 ? 'active' : ''}`}>Result</div>
                <p className={`point ${stepsCompleted >= 6 ? 'active' : ''}`}>6</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };