import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './CorrectorBody.css';
import { trackEvent } from '../../analytics';
import CorrectorModal from '../CorrectorModal/CorrectorModal';

const CorrectorBody = ({ urlWebsite, userInfos }) => {
  const [loading, setLoading] = useState(false);
  const [showImprovement, setShowImprovement] = useState(false);
  const [improvementText, setImprovementText] = useState('');
  const [modalError, setModalError] = useState({ isOpen: false, title: '', message: '' });
  const [redirectOnClose, setRedirectOnClose] = useState(false);
  const [actionType, setActionType] = useState(''); // 'approve', 'revise', ou 'error'
  
  const iframeRef = useRef(null);
  const navigate = useNavigate();

  // Au rechargement, vérifie si un flag de redirection a été défini dans le localStorage
  useEffect(() => {
    const redirectFlag = localStorage.getItem('redirectAfterReload');
    if (redirectFlag === 'immediate') {
      localStorage.removeItem('redirectAfterReload');
      navigate('/dashboard'); // Redirection immédiate
    } else if (redirectFlag === 'delayed') {
      localStorage.removeItem('redirectAfterReload');
      setTimeout(() => {
        navigate('/dashboard'); // Redirection après 5 secondes
      }, 5000);
    }
  }, [navigate]);

  useEffect(() => {
    if (urlWebsite && iframeRef.current) {
      iframeRef.current.src = urlWebsite + `index.html?v=${Date.now()}`;
    }
  }, [urlWebsite]);

  // Lors du clic sur OK de la modale
  const handleCloseModal = () => {
    setModalError({ isOpen: false, title: '', message: '' });
    if (redirectOnClose) {
      // Définir un flag dans localStorage selon le type d'action
      if (actionType === 'approve') {
        localStorage.setItem('redirectAfterReload', 'immediate');
      } else if (actionType === 'revise') {
        localStorage.setItem('redirectAfterReload', 'delayed');
      }
      window.location.reload();
    }
  };

  const handlePublish = async () => {
    trackEvent('click', 'PublishWebsite', 'User approved the website', 1);
    setLoading(true);
    const id_gen = userInfos.generation[0].id_gen;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACK_URL}/approved`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id_gen, approved: true })
      });
      if (response.ok) {
        const result = await response.json();
        setRedirectOnClose(true);
        setActionType('approve');
        setModalError({ isOpen: true, title: 'Success', message: result.message || 'Your site is ready!' });
      } else {
        let result = {};
        try {
          result = await response.json();
        } catch (e) {}
        setRedirectOnClose(false);
        setActionType('error');
        setModalError({
          isOpen: true,
          title: 'Error',
          message: result.message || "An error occurred while processing your request."
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setRedirectOnClose(false);
      setActionType('error');
      setModalError({
        isOpen: true,
        title: 'Error',
        message: "An error occurred while processing your request."
      });
    } finally {
      setLoading(false);
    }
  };

  // Bascule de la zone de révision lors du clic sur "Revise"
  const handleRevise = () => {
    setShowImprovement(prev => !prev);
  };

  const handleSubmitRevision = async () => {
    trackEvent('click', 'SubmitRevision', 'User submitted a revision', 1);
    const id_gen = userInfos.generation[0].id_gen;
    if (improvementText.trim() === '') {
      setModalError({
        isOpen: true,
        title: 'Warning',
        message: "Please enter your suggestions before validating."
      });
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACK_URL}/correction`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id_gen,
          suggestion: improvementText.trim(),
          fileLink: urlWebsite 
        })
      });
      const result = await response.json();
      console.log('Raw response:', result);
      if (response.ok) {
        setModalError({
          isOpen: true,
          title: 'Success',
          message: result.message
        });
        setRedirectOnClose(true);
        setActionType('revise');
      } else {
        setRedirectOnClose(false);
        setActionType('error');
        setModalError({
          isOpen: true,
          title: 'Error',
          message: result.error || result.message || "An error occurred while sending your feedback."
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setRedirectOnClose(false);
      setActionType('error');
      setModalError({
        isOpen: true,
        title: 'Error',
        message: "An error occurred while sending your feedback."
      });
    } finally {
      setLoading(false);
      setShowImprovement(false);
      setImprovementText('');
    }
  };

  return (
    <div className="corrector-body">
      {loading && (
        <div id="loader" className="loader">
          <div className="spinner"></div>
          <p className="loading-text">Please wait a few moments</p> {/* Nouveau texte ajouté */}
        </div>
      )}
      {urlWebsite ? (
        <iframe
          ref={iframeRef}
          className="web_target"
          title="Website Preview"
          frameBorder="0"
        ></iframe>
      ) : (
        <div>Loading site...</div>
      )}
      <div className="chat-container">
        <div className="chat-header">
          <div className="logo">W</div>
          <h2>Weebseat AI</h2>
        </div>
        <div className="chat-content">
          <div className="intro">
            <p><strong>Your site is ready!</strong></p>
            <p>
              If it suits you, we can publish it immediately; <strong>otherwise</strong>, let us know what you'd like to change and our AI will apply your requests in real time!
            </p>
          </div>
          <div className="choices">
            <div className="choice" onClick={handlePublish}>
              <span className="icon">✅</span>
              <span>Approve</span>
            </div>
            <div className="choice" onClick={handleRevise}>
              <span className="icon">✏️</span>
              <span>Revise</span>
            </div>
          </div>
          <div className={`improvement-box ${showImprovement ? 'open' : ''}`}>
            <div className="improvement-content">
              <p>
                Please describe what you'd like to change. We'll update your site instantly so you can see the final result!
              </p>
              <textarea
                rows="4"
                placeholder="Enter your suggestions here"
                value={improvementText}
                maxLength={200}
                onChange={(e) => setImprovementText(e.target.value)}
              ></textarea>
              <div className="char-counter">
                {200 - improvementText.length}/200
              </div>
              <button onClick={handleSubmitRevision}>Submit Revision</button>
              <div className="tooltip-container">
                <span className="tooltip-icon">?</span>
                <span className="tooltip-text">
                  Use the suggestion box to describe the modifications, and our AI will update your site in real-time.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CorrectorModal
        isOpen={modalError.isOpen}
        title={modalError.title}
        message={modalError.message}
        onClose={handleCloseModal}
      />
    </div>
  );
};

export default CorrectorBody;
