import React, { useEffect, useCallback, useState } from 'react';
import { RoadMap } from '../RoadMap/RoadMap';
import './Step2.css';
import LogoUpload from '../LogoUpload/LogoUpload';
import { DoubleRightOutlined, ReloadOutlined } from '@ant-design/icons';

export const Step2 = ({ 
  handleClick, 
  step, 
  setInfoLogoWebsite, 
  setColorLogoWebsite, 
  setUrlLogoWebsite, 
  setStepValid, 
  setErrorMsg, 
  urlLogoWebsite, 
  infoLogoWebsite, 
  colorLogoWebsite, 
  goToStep 
}) => {
  // State pour forcer le re-mount de LogoUpload lors du reset
  //const [resetKey, setResetKey] = useState(0);

  /**
   * Met à jour la description du logo.
   * @param {string} description - La nouvelle description du logo.
   */
  const handleInfoLogo = (description) => {
    localStorage.setItem('infoLogoWebsite', description);
    console.log('infoLogoWebsite', description);
    setInfoLogoWebsite(description);
  };

  /**
   * Met à jour la couleur du logo.
   * @param {string} color - La nouvelle couleur du logo.
   */
  const handleColorLogo = (color) => {
    localStorage.setItem('colorLogoWebsite', color);
    console.log('colorLogoWebsite', color);
    setColorLogoWebsite(color);
  };

  /**
   * Met à jour l'URL du logo.
   * @param {string} url - La nouvelle URL du logo.
   */
  const handleUrlLogo = (url) => {
    console.log('urlLogoWebsite', url);
    if(url === ''){
      localStorage.removeItem('urlLogoWebsite');
    }
    else{
    localStorage.setItem('urlLogoWebsite', url);
    }
    setUrlLogoWebsite(url);
  };

  /**
   * Réinitialise l'upload et les champs texte du logo.
   * Supprime les infos du localStorage et vide les champs visuellement.
   */
  /*
  const handleReset = () => {
    localStorage.removeItem('infoLogoWebsite');
    localStorage.removeItem('colorLogoWebsite');
    localStorage.removeItem('urlLogoWebsite');
    setInfoLogoWebsite('');
    setColorLogoWebsite('');
    setUrlLogoWebsite('');
    // Incrémente la clé pour forcer le re-mount du composant LogoUpload
    setResetKey(prev => prev + 1);
  };
*/
  /**
   * Valide l'étape.
   * Règles de validation :
   * - "Colour" est obligatoire et doit être exempt de caractères spéciaux.
   * - Au moins l'URL du logo (si uploadée et valide) ou la description doit être fournie.
   */
  const validateStep = useCallback(() => {
    // Pour l'URL, on vérifie qu'elle est non vide et valide en essayant de créer un objet URL.
    let isUrlValid = urlLogoWebsite !== '' ? true : false;

    // Pour la description, on vérifie qu'elle ne contient pas de caractères spéciaux.
    const specialCharRegex = /[^a-zA-Z0-9\s]/;
    const isInfoValid = infoLogoWebsite && !specialCharRegex.test(infoLogoWebsite);
    // Pour la couleur, on vérifie qu'elle est présente et sans caractères spéciaux.
    const isColorValid = colorLogoWebsite && !specialCharRegex.test(colorLogoWebsite);

    // La couleur doit toujours être valide, et au moins une des deux informations sur le logo doit être présente :
    // - Si une URL valide est présente, la description est facultative.
    // - Sinon, la description doit être renseignée.
    if (isColorValid && (isUrlValid || (!isUrlValid && isInfoValid))) {
      setStepValid(true);
      setErrorMsg('');
    } else {
      setStepValid(false);
      setErrorMsg('Please provide a valid colour and either upload a valid logo URL or fill in the logo description without special characters.');
    }
  }, [urlLogoWebsite, infoLogoWebsite, colorLogoWebsite, setStepValid, setErrorMsg]);

  useEffect(() => {
    validateStep();
  }, [urlLogoWebsite, infoLogoWebsite, colorLogoWebsite, validateStep]);

  return (
    <div className="step2">
      <h1>UPLOAD YOUR LOGO</h1>
      <LogoUpload  logoUrl={handleUrlLogo} />
      <h1>OR CREATE ONE</h1>
      <input 
        type="text" 
        placeholder="Logo Description" 
        value={infoLogoWebsite}
        onChange={(e) => handleInfoLogo(e.target.value)} 
      />
      <input 
        type="text" 
        placeholder="Colour" 
        value={colorLogoWebsite}
        onChange={(e) => handleColorLogo(e.target.value)} 
      />
      {/* Bouton reset discret sous forme d'icône 
      <button className="reset-icon-button" onClick={handleReset} title="Reset">
        <ReloadOutlined />
      </button>
      */}
      <div>
        <button className="gradient-button" onClick={handleClick}>
          Next &nbsp;<DoubleRightOutlined />
        </button>
      </div>
      <RoadMap stepsCompleted={step} goToStep={goToStep} />
    </div>
  );
};
