
import { useNavigate } from 'react-router-dom';
//import env from 'react-dotenv';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { Form, Button, Space, ConfigProvider } from 'antd';
import { FacebookFilled, GoogleCircleFilled, AppleOutlined, MailFilled} from '@ant-design/icons';
import Alertbox from '../../components/Alertbox/Alertbox';
import 'antd/dist/reset.css';
import './Signin.css'
import { Footer } from '../../components/Footer/Footer';
//import AppleLogin from 'react-apple-login';
import AppleSignin from 'react-apple-signin-auth';
import { trackPageView } from '../../analytics';


const Signin = ({ passLoginStatus, onLogged }) => {
  const navigate = useNavigate();

  /**
   * This effect is triggered when the Signin component is mounted.
   * It calls the function `trackPageView` with the argument '/signin'.
   * This is likely used for tracking page views for analytics or logging.
   * 
   * The empty dependency array ensures that this effect only runs once,
   * at the component's initial render, mimicking the behavior of componentDidMount.
   */
  useEffect(() => {
      trackPageView('/signin'); // Indique la route ou la page
  }, []);


  // ETAT INITIAUX
  const [message, setMessage] = useState('');
  const [visible, setVisible] = useState(false);





/**
 * This function checks if the user has any generations.
 *
 * It sends a GET request to the backend with the user's credentials.
 * The backend then checks if the user has any generations and returns the result.
 * If the user has generations, the function returns true.
 * If the user does not have any generations, the function returns false.
 * If there is an error while checking, the function logs the error and returns false.
 *
 * @returns {boolean} - True if the user has generations, false otherwise.
 */
const checkUserGenerations = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/generation`, { withCredentials: true });
    console.log('user has generation', response.data.generation);
    return response.data.generation;
  } catch (err) {
    console.error('Error checking user generations:', err);
    return false;
  }
};

  /**
   * This function is used to close the alert message.
   * It sets the visibility of the alert to false and clears the message.
   */
  const handleAlertClose = async () => {
    setVisible(false);
    setMessage('');
  };

/**
 * This function checks if the user has accepted cookie consent.
 *
 * It retrieves the 'cookieConsent' value from local storage.
 * If the value is not 'accepted', it sets an error message and displays it to the user.
 * If the value is 'accepted', it returns true.
 *
 * @returns {boolean} - True if the user has accepted cookie consent, false otherwise.
 */
const checkCookieConsent = () => {
  const cookieConsent = localStorage.getItem('cookieConsent');
  if (cookieConsent !== 'accepted') {
    // Enregistrer l'acceptation, puisque le login équivaut à l'acceptation
    localStorage.setItem('cookieConsent', 'accepted');
  }
  return true;
};

/**
 * This function handles the Facebook login process.
 *
 * On success, it sends a POST request to the backend with the response from Facebook.
 * The backend then handles the authentication and returns a success message along with the user data.
 * If the login is successful, the user data is stored in local storage and the login status is passed to the parent component.
 * The user is then navigated to the dashboard or the generator page based on whether they have generations or not.
 *
 * On error, it sets an error message and displays it to the user.
 *
 * On cancel, it sets a warning message indicating that the Facebook login was canceled and displays it to the user.
 */
const facebookLogin = () => {

  if (!checkCookieConsent()) return; // Vérification des cookies

  window.FB.login(function (response) {
    console.log('Facebook response', response)
    if (response.authResponse && response.authResponse !== null) {
      console.log('Facebook response', response)
      fetch(`${process.env.REACT_APP_BACK_URL}/auth/facebook`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(response)
      })
      .then((res) => res.json())
      .then(async (res) => {
        if (res.success) {
          localStorage.setItem('user', JSON.stringify(res.user))
          await passLoginStatus(true)
          if (onLogged !== undefined) {
            onLogged()
          }
          setTimeout(async () => {
            const hasGenerations = await checkUserGenerations()
            if (hasGenerations) {
              navigate('/dashboard')
            } else {
              navigate('/generator')
            }
          }, 2000); // 2 seconds delay
        } else {
          setMessage(<Alertbox type="error" text={res.message} />)
          setVisible(true)
          setTimeout(handleAlertClose, 2000)
        }
      })
    } else if(response.authResponse === null && response.status === "unknown"){
      setMessage(<Alertbox type="warning" text="Login by Facebook canceled." />)
      setVisible(true)
      setTimeout(handleAlertClose, 2000)
    } else {
      setMessage(<Alertbox type="error" text="Cannot login by facebook" />)
      setVisible(true)
      setTimeout(handleAlertClose, 2000)
    }
  }, {scope: 'email'})
}

/**
 * This function handles the Google login process.
 *
 * On success, it sends a POST request to the backend with the token response from Google.
 * The backend then handles the authentication and returns a success message along with the user data.
 * If the login is successful, the user data is stored in local storage and the login status is passed to the parent component.
 * The user is then navigated to the dashboard or the generator page based on whether they have generations or not.
 *
 * On error, it sets an error message and displays it to the user.
 *
 * On non-OAuth error, it sets a warning message indicating that the Google login was canceled and displays it to the user.
 */
const googleLogin = useGoogleLogin({
  onSuccess: (tokenResponse) => {
    if (!checkCookieConsent()) return; // Vérification des cookies

    fetch(`${process.env.REACT_APP_BACK_URL}/auth/google`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tokenResponse),
    })
    .then((res) => res.json())
    .then(async (res) => {
      if (res.success) {
        localStorage.setItem('user', JSON.stringify(res.user));
        await passLoginStatus(true);
        if (onLogged !== undefined) {
          onLogged();
        }
        setTimeout(async () => {
          const hasGenerations = await checkUserGenerations();
          if (hasGenerations) {
            navigate('/dashboard');
          } else {
            navigate('/generator');
          }
        }, 2000); // 2 seconds delay
      } else {
        setMessage(<Alertbox type="error" text={res.message} />);
        setVisible(true);
        setTimeout(handleAlertClose, 2000);
      }
    });
  },
  onError: () => {
    setMessage(<Alertbox type="error" text="Cannot login by google." />);
    setVisible(true);
    setTimeout(handleAlertClose, 2000);
  },
  onNonOAuthError: () => {
    setMessage(<Alertbox type="warning" text="Login by Google canceled." />);
    setVisible(true);
    setTimeout(handleAlertClose, 2000);
  },
});

const handleEmailSignin = (e) => {
  if (!checkCookieConsent()) return; // Vérification des cookies
    navigate('/register')
}

  //RENDU
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: `linear-gradient(to right, #F1A300, #CB1000)`,
              colorPrimaryBorder:`linear-gradient(to right, #F1A300, #CB1000)`,
              colorPrimaryHover: `linear-gradient(185deg, #CB1000, #F1A300)`,
              colorPrimaryActive: `linear-gradient(185deg, #CB1000, #F1A300)`,
              lineWidth: 1,
            },
            Checkbox: {
              colorPrimary: `#F1A300`,
              colorPrimaryHover: `#F1A300`,
              colorPrimaryActive: `#CB1000`,
            },
            Input:{
              height:50,
            }
          },
        }}
      >
      <div className="steps-home-login signin-login">
        <div className="loginMainContainer">
        <div className="alert-cont">
            <Space
              direction="vertical"
              style={{
                width: '100%',
              }}
            >
              {visible && message}
            </Space>
          </div>
          <div className="loginFormContainer loginFormContainerSignin">
            <div className="loginFormOnboardingTitle">
              <div className="loginFormTitle ">
                <p className='signin-title'>
                    Join us and publish your site in just a few seconds!
                </p>
              </div>
              {/*
              <div className="loginFormPara">
                <p>Securing the places where people live and thrive.</p>
              </div>
              */}
            </div>
            <div className="LoginForm">
              <div className="loginTitle">
                
              </div>
              <Form 
                name="normal_login" 
                style={{width: "100%"}}
                initialValues={{ remember: true }}
              >
              <Form.Item className="login-form">
                <Button
                  block
                  className="social-login-button facebook-button"
                  icon={<FacebookFilled />}
                  style={{ marginBottom: 10 }}
                  onClick={facebookLogin}
                  >
                  Facebook
                </Button>
                <Button
                  block
                  className="social-login-button google-button"
                  icon={<GoogleCircleFilled />}
                  style={{ marginBottom: 10 }}
                  onClick={() => googleLogin()}
                  >
                  Google
                </Button>
                {/**
                 
                <Button
                  block
                  className="social-login-button apple-button"
                  icon={<AppleOutlined />}
                  style={{ marginBottom: 10 }}
                  onClick={() => appleLogin()}
                  >
                  Apple
                </Button>
                 */}
                <AppleSignin
                /** Auth options passed to AppleID.auth.init() */
                authOptions={{
                  /** Client ID - eg: 'com.example.com' */
                  clientId: 'com.weebseat.login.service',
                  /** Requested scopes, seperated by spaces - eg: 'email name' */
                  scope: 'email name',
                  /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                  redirectURI: `${process.env.REACT_APP_FRONT_URL}/login`,
                  /** State string that is returned with the apple response */
                  state: 'state',
                  /** Nonce */
                  nonce: 'nonce',
                  /** Uses popup auth instead of redirection */
                  usePopup: true,
                }} // REQUIRED
                /** General props */
                uiType="dark"
                /** className */
                className="apple-auth-btn"
                /** Removes default style tag */
                noDefaultStyle={false}
                /** Allows to change the button's children, eg: for changing the button text */
                buttonExtraChildren="Continue with Apple"
                /** Extra controlling props */
                /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
                onSuccess={async (response) => {
                  if (!checkCookieConsent()) return;
                  try {
                    const res = await fetch(`${process.env.REACT_APP_BACK_URL}/auth/apple`, {
                      method: 'POST',
                      credentials: 'include',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify(response),
                    });
              
                    const data = await res.json();
              
                    if (data.success) {
                      localStorage.setItem('user', JSON.stringify(data.user));
                      await passLoginStatus(true);
                      if (onLogged) {
                        onLogged();
                      }
                      setTimeout(async () => {
                        const hasGenerations = await checkUserGenerations();
                        if (hasGenerations) {
                          navigate('/dashboard');
                        } else {
                          navigate('/generator');
                        }
                      }, 2000); // 2 seconds delay
                    } else {
                      setMessage(<Alertbox type="error" text={data.message} />);
                      setVisible(true);
                      setTimeout(handleAlertClose, 2000);
                    }
                  } catch (error) {
                    console.error('Error sending data to backend:', error);
                    setMessage(<Alertbox type="error" text="An error occurred while logging in with Apple." />);
                    setVisible(true);
                    setTimeout(handleAlertClose, 2000);
                  }
                }}
                onError={(error) => {
                  console.error(error)
                  if(error['error'] === 'popup_closed_by_user'){
                    setMessage(<Alertbox type="warning" text="Login by Apple canceled." />);
                    setVisible(true);
                    setTimeout(handleAlertClose, 2000);
                  }else{
                    setMessage(<Alertbox type="error" text="Apple Login failed." />);
                    setVisible(true);
                    setTimeout(handleAlertClose, 2000);
                  }
                }}
                /** Skips loading the apple script if true */
                skipScript={false} // default = undefined
                /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
                render={(props) => <Button 
                {...props}
                icon={<AppleOutlined />}
                style={{ marginBottom: 10 }}
                className="social-login-button apple-button"
                >
                  Apple
                </Button>}
              />
                <Button
                  block
                  className="social-login-button mail-button"
                  icon={<MailFilled style={{ width: 26 }}/>}
                  style={{ marginBottom: 10 }}
                  onClick={handleEmailSignin}
                  >
                  E-mail
                </Button>
              </Form.Item>
              <div className='text-addon-signin'>
                Create your account and see how easy it is.
              </div>
              <div className='disclamer'>
                  By signin up you agree to Weebseat <a href="https://weebseat.com/landing/privacy-policy.html" >&nbsp;Privacy policy</a>&nbsp;and&nbsp;<a href="https://weebseat.com/landing/terms-of-use.html">Terms of service</a>
              </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
      </ConfigProvider>
    </>
  );
};

export default Signin;
