import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Corrector.css';
import { Footer } from '../../components/Footer/Footer';
import CorrectorBody from '../../components/CorrectorBody/CorrectorBody';

const Corrector = ({ passLoginStatus, loginStatus, generationStatus, handlelogin }) => {
  const navigate = useNavigate();
  const [userInfos, setUserInfos] = useState(false);
  const [urlWebsite, setUrlWebsite] = useState(''); // Stocke l'URL du site généré
  const [revisorApproved, setRevisorApproved] = useState(false);

  // Requête POST vers /revisor uniquement si l'URL actuelle est "/revisor"
  useEffect(() => {
    if (window.location.pathname === '/revisor' && urlWebsite) {
      const postRevisor = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACK_URL}/revisor`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ currentUrl: '/revisor', urlWebsite })
          });
          const data = await response.json();
          console.log("Response from /revisor:", data);
          if (data.success) {
            setRevisorApproved(true);
            // Si le patch a été appliqué, forcer un reload une seule fois
            if (data.patchApplied && !window.location.search.includes('reloaded=true')) {
              const separator = window.location.search ? '&' : '?';
              window.location.href = window.location.href + separator + 'reloaded=true';
            }
          } else {
            console.error("Revisor response error:", data);
          }
        } catch (error) {
          console.error("Error posting to /revisor:", error);
        }
      };
      postRevisor();
    } else {
      // Si on n'est pas sur /revisor, on considère qu'il n'y a pas de traitement particulier à attendre
      setRevisorApproved(true);
    }
  }, [urlWebsite]);

  // Récupération des infos utilisateur et de l'URL du site généré
  useEffect(() => {
    const infos_user = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACK_URL}/getinfosuser`, {
          method: 'GET',
          credentials: 'include',
        });
        const data = await response.json();
        if (data.expired) {
          console.log('session expired');
          navigate('/login'); // Redirige vers la page de login si la session a expiré
        } else {
          setUserInfos(data);
          // Si le crédit de régénération est 0, rediriger directement vers /dashboard
          if (data.regeneration_credit === 0) {
            navigate('/dashboard');
            return;
          }
          // Si des infos de génération existent
          if (data.generation && data.generation.length > 0) {
            // On prend la première génération qui correspond au dernier site généré
            const lastGeneration = data.generation[0];
            if (lastGeneration.last_gen_status === 'OK') {
              // Construit l'URL du site : REACT_APP_GENERATION + w_directory + index.html
              const websiteUrl = `${process.env.REACT_APP_GENERATION}/${lastGeneration.w_directory}/`;
              setUrlWebsite(websiteUrl);
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    };
    infos_user();
  }, [navigate]);

  // Effet pour recharger la page si le chargement dure plus de 5 secondes
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!(userInfos && urlWebsite && revisorApproved)) {
        console.log("reload the page...");
        window.location.reload();
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [userInfos, urlWebsite, revisorApproved]);

  // Affiche CorrectorBody uniquement lorsque userInfos, urlWebsite et revisorApproved sont disponibles
  return (
    <>
      {userInfos && urlWebsite && revisorApproved ? (
        <CorrectorBody className="corrector-body" urlWebsite={urlWebsite} userInfos={userInfos} />
      ) : (
        <div>Loading site...</div>
      )}
      <Footer />
    </>
  );
};

export default Corrector;
