import React, { useState, useEffect } from 'react';
import { ConfigProvider,Button, Modal, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import './MainSub.css';
import StripeButton from '../StripeButton/StripeButton';
import UnsubscribeBtn from '../UnsubscribeBtn/UnsubscribeBtn'
import SubscriptionHistory from '../SubscriptionHistory/SubscriptionHistory';
//import env from 'react-dotenv';
import checkIcon from '../../assets/img/checkIcon.png';

/**
 * Returns a formatted date string representing the current date.
 * The format is "DD MMM" where DD is the day of the month,
 * and MMM is the abbreviated month name.
 *
 * @returns {string} The formatted date string.
 */
const getDate = () => {
  const today = new Date().toString().split(' ');
  return `${today[2]} ${today[1]}`;
};

const MainSub = ({ userInfos, isSubValid }) => {
  const navigate = useNavigate();
  console.log('userInfos',userInfos)
  console.log('validité abo:',isSubValid)
  const [currentDate] = useState(getDate());
  const [transactions, setTransactions] = useState([]); 
  const [loading, setLoading] = useState(false);

  // Pour éviter de re-fetch si on a déjà chargé une fois
  const [hasFetched, setHasFetched] = useState(false);

  // On démarre en 'yearly', par exemple
  const [currentCycling, setCurrentCycling] = useState('yearly');

  /**
   * fetchTransactions : fonction asynchrone pour récupérer les transactions.
   */
  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACK_URL}/transactions`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: userInfos?.id }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setTransactions(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des transactions:', error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * useEffect pour charger les transactions une seule fois:
   * - Si userInfos.id existe
   * - Si on n’a pas déjà fetch
   */
  useEffect(() => {
    if (userInfos?.id && !hasFetched) {
      fetchTransactions();
      setHasFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfos?.id, hasFetched]);
  // Ici, on ajoute userInfos?.id au tableau
  // pour lancer le fetch dès qu’on connaît l’ID.
  // "hasFetched" évite de relancer 50 fois si userInfos change brièvement.

  /**
   * Modifie le cycle de facturation (monthly ou yearly).
   * On log la valeur `cycle` (qui est la valeur *à venir*),
   * plutôt que `currentCycling` (qui est l’ancienne valeur 
   * dans l’immédiat de l’appel).
   */
  const handleCycling = (cycle) => {
    setCurrentCycling(cycle);
    console.log('Cycle choisi :', cycle);
  };

  // Pour bien voir quand currentCycling change *après* la maj du state
  useEffect(() => {
    console.log('Le state currentCycling est maintenant :', currentCycling);
  }, [currentCycling]);


  const success = () => {
  
    Modal.success({
      title: (<div className='mod-title'>Subscription Deleted</div>),
      content: (
        <div className='mod-txt'>
          <p>Your subscription has been successfully deleted.</p>
        </div>
      ),
      width: 700,
      okText: 'OK',
      okButtonProps: {
        style: {
          backgroundColor: '#E16400',
          borderColor: '#E16400',
          borderRadius: '8px',
          fontSize: '18px',
          fontWeight: 700,
          height: '40px',
        },
      },
      onOk: () => navigate('/dashboard'), // Redirection après confirmation
    });
  };
  
  const error = () => {
    
  
    Modal.error({
      title: (<div className='mod-title'>Subscription NOT Deleted</div>),
      content: (
        <div className='mod-txt'>
          <p>Some error happened. Please try to unsubscribe later.</p>
        </div>
      ),
      width: 700,
      okText: 'OK',
      okButtonProps: {
        style: {
          backgroundColor: '#E16400',
          borderColor: '#E16400',
          borderRadius: '8px',
          fontSize: '18px',
          fontWeight: 700,
          height: '40px',
        },
      },
      onOk: () => navigate('/dashboard'), // Redirection après confirmation
    });
  };


/*
  const handleUnsubscribe = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACK_URL}/unsubscribe`, {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ user_id: userInfos?.id }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Desabonnement: ',data)
      if(data.status == 'ok'){
        success()
      }else{
        error()
      }
    } catch (error) {
      console.error('Erreur lors du désabonnement:', error);
    }
  }
*/
const handleUnsubscribe = (e) => {
  navigate('/support')
}
    // Tableau de détails : notez que le 4e élément est du JSX
    const details = [
        'Publish and Launch your own site',
        'Generate your Business Landing Page',
        'Unique free Weebseat.com Domain',
        <>
          Smart Design: <span className="gradient-text gras">AI</span> Picks the Best
        </>,
        'Owned Images & logo Generator',
        'Link your Social Accounts',
        'Priority Support',
        'Not happy? Let AI redesign it for you!',
      ];


  return (
    <ConfigProvider>
      <div className="main">
      
        <div className="dashboardPaymentSecTitle">
          <h1>{!isSubValid ? 'Payment Method' : 'Subscription'}</h1>
          <p>{currentDate}</p>
        </div>
        
        <div className="step7">
          <div className="payment-box2">
            {!isSubValid  && (
              <>
                <h1>Payment Option</h1>
                <div className="payment-options">
              <div className="landing-pricing-description-switch-box">
                <div
                  className={
                    currentCycling === 'monthly'
                      ? 'landing-pricing-description-switch-box-left cycle-selected'
                      : 'landing-pricing-description-switch-box-left'
                  }
                  onClick={() => handleCycling('monthly')}
                >
                  Monthly
                </div>
                <div
                  className={
                    currentCycling === 'yearly'
                      ? 'landing-pricing-description-switch-box-right cycle-selected'
                      : 'landing-pricing-description-switch-box-right'
                  }
                  onClick={() => handleCycling('yearly')}
                >
                  Yearly
                </div>
              </div>
                </div>

                <div className="BP-cont">
              <div className="landing-pricing-plan landing-pricing-plan-light no-blur">
                <div>
                  <div>
                    <h2 className="landing-pricing-plan-name-dark">Kickstart Plan</h2>
                  </div>
                  <div className="landing-pricing-plan-price-box">
                    <div className="landing-pricing-plan-discount-box">{currentCycling === 'yearly'? (<h4 className="general-gradient-bg landing-pricing-plan-standard-discount">-16%</h4>): '' }</div>
                    <span className="landing-pricing-plan-price">{ currentCycling === 'yearly'? '$2.50' : '$2.99'}</span>
                    <span className="landing-pricing-plan-time-dark">
                      Per Month <div>{ currentCycling === 'yearly'? 'Billed yearly' : 'Billed monthly' }</div>
                    </span>
                  </div>
                  <div className="landing-pricing-plan-punchline">
                    The <strong>easiest</strong> way to create and grow your website with{' '}
                    <span className="gradient-text gras">AI</span>
                  </div>
                  <div className="landing-pricing-plan-separator" />
                </div>

                <div className="landing-pricing-plan-details-container">
                    {details.map((detail, index) => (
                        <div key={index} className="landing-pricing-plan-detail-box">
                          <div className="landing-pricing-plan-detail-check">
                            <img src={checkIcon} alt="checked" />
                          </div>
                          <p className="landing-pricing-plan-detail-para-dark">{detail}</p>
                        </div>
                    ))}
                </div>
                <div className="choose-plan-box">
                  <div className="choose-plan-button">
                    <StripeButton cycle={currentCycling}/>
                  </div>
                </div>
              </div>
                </div>
              </>
            )}
            <>
              {loading ? (
                <p>Loading transactions...</p>
              ) : (
                <SubscriptionHistory transactions={transactions} />
              )}
            </>
              {isSubValid  && (
                <>
                  <h1>Subscription</h1>
                  <div className="payment-options">
                  <UnsubscribeBtn unsubscribe={handleUnsubscribe}/>
                  </div>
  
  
                </>
              )}
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default MainSub;
