import React, { useEffect, useCallback, useRef, useState, useMemo } from 'react';
import { Progress  } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import env from "react-dotenv";
import { RoadMap } from '../RoadMap/RoadMap';
import largeCircle from '../../assets/img/Ellipse 5 (2).png';
import mediumCircle from '../../assets/img/Ellipse 5 (1).png';
import smallCircle from '../../assets/img/Ellipse 5.png';
import './Step5.css';
import './Loader.css';

/**
 * An object defining a color gradient transition.
 * The keys represent the percentage of the transition, 
 * and the values represent the corresponding color at that point.
 * 
 * The gradient transitions from '#F1A300' at 0% to '#CB1000' at 100%.
 */
const twoColors = {
  '0%': '#F1A300',
  '100%': '#CB1000',
};

export const Step5 = ({
  step, 
  nameWebsite,
  themeWebsite,
  infoLogoWebsite,
  colorLogoWebsite,
  urlLogoWebsite,
  shopEmail,
  shopPhone,
  setStepValid,
  setErrorMsg,
  loggedStatus,
  setHasGeneration,
  goToStep,
  setThrowError,
  handleStep5Error 
}) => {
  /**
   * Hook to navigate between routes.
   */
  const navigate = useNavigate();

  /**
   * useRef to handle the request state, preventing multiple requests from being sent.
   */
  const requestSent = useRef(false);

  /**
   * useState for managing the current informational message displayed to the user.
   * Initial state is 'Starting Generation'.
   */
  const [currentMessage, setCurrentMessage] = useState('Starting Generation');

  /**
   * useState to handle the CSS class for fade-in animations.
   * Initial state is an empty string.
   */
  const [fadeClass, setFadeClass] = useState('');

  /**
   * useState to track the progress percentage of a certain process.
   * Initial state is 0%.
   */
  const [percentage, setPercentage] = useState(0);

  /**
   * useState to count the number of times the progress percentage has not changed.
   * Initial state is 0.
   */
  const [staticPercentageCount, setStaticPercentageCount] = useState(0);

  /**
   * useRef to store the previous progress percentage, aiding in detecting changes.
   * Initial state is 0.
   */
  const previousPercentage = useRef(0);

  const [hasClearedStorage, setHasClearedStorage] = useState(false);

  /**
   * Clears specific items from the local storage.
   * 
   * This function removes the following keys from the local storage:
   * - 'nameWebsite'
   * - 'themeWebsite'
   * - 'infoLogoWebsite'
   * - 'colorLogoWebsite'
   * - 'urlLogoWebsite'
   * - 'shopEmail'
   * - 'shopPhone'
   * - 'currentStep'
   * 
   * It logs a message to the console indicating that the local storage is being cleared.
   */
  const clearLocalStorage = useCallback(() => {
    console.log("Clearing local storage...");
    localStorage.removeItem('nameWebsite');
    localStorage.removeItem('themeWebsite');
    localStorage.removeItem('infoLogoWebsite');
    localStorage.removeItem('colorLogoWebsite');
    localStorage.removeItem('urlLogoWebsite');
    localStorage.removeItem('shopEmail');
    localStorage.removeItem('shopPhone');
    localStorage.removeItem('currentStep');
  }, []);

  /**
   * Function to check the generation status of a website creation request.
   * This function uses Axios to send a GET request to the backend to check the current status.
   * 
   * The function defines an inner asynchronous function 'checkStatus' that performs the following:
   * - Sends a GET request to `${env.REACT_APP_BACK_URL}/status`
   * - Logs the response received.
   * - Updates the progress percentage if it has changed since the last check.
   * - Handles the case where the generation is complete by navigating to the dashboard.
   * - Handles the case of static progress by incrementing the percentage if necessary.
   * 
   * The function sets an interval to call 'checkStatus' every 5 seconds.
   * The interval is cleared when the component using this function unmounts.
   * 
   * The function depends on 'navigate', 'setHasGeneration', and 'staticPercentageCount'.
   */
  const checkGenerationStatus = useCallback(() => {
    console.log("Checking generation status...");

    const checkStatus = async () => {
      try {
        const response = await axios.get(`${env.REACT_APP_BACK_URL}/status`, { withCredentials: true });
        console.log('Verification de l etat GET', response);

        if (response.data.progress >= 0) {
          if (!hasClearedStorage) {
            clearLocalStorage();
            setHasClearedStorage(true);
          }
          if (response.data.progress === 100) {
            console.log("Generation complete.");
            setHasGeneration(true);
            setTimeout(() => {
              navigate('/revisor');
            }, 3000);
            
          } else {
            if (response.data.progress === previousPercentage.current) {
              setStaticPercentageCount(prev => prev + 1);
            } else {
              setStaticPercentageCount(0);
              setPercentage(response.data.progress);
            }
            previousPercentage.current = response.data.progress;

            if (staticPercentageCount >= 2) {
              const randomIncrement = Math.floor(Math.random() * 2) + 1;
              setPercentage(prev => prev + randomIncrement);
              setStaticPercentageCount(0);
            }
          }
        }
      } catch (error) {
        console.error('Error checking generation status:', error);
        handleStep5Error('Error checking generation status');
      }
    };

    //checkStatus(); // Check status immediately
    const statusInterval = setInterval(async ()=>{await checkStatus()}, 5000); // Check status every 5 seconds

    return () => {
      clearInterval(statusInterval);
    };
  }, [navigate, setHasGeneration,staticPercentageCount, handleStep5Error,clearLocalStorage]);

  /**
   * useEffect to continuously check the generation status.
   * 
   * This effect initiates the `checkGenerationStatus` function, which sends periodic
   * requests to verify the status of a website generation process.
   * 
   * The `cleanup` function returned by `checkGenerationStatus` is used to clear the interval
   * when the component unmounts, ensuring no memory leaks occur due to hanging intervals.
   * 
   * Dependencies:
   * - `checkGenerationStatus` which contains the logic for checking the status.
   */
  useEffect(() => {
    const cleanup = checkGenerationStatus();
    return cleanup;
  }, [checkGenerationStatus]);


  /**
   * Sends a generation request for creating a website.
   * 
   * This function performs the following actions:
   * - Checks if a previous request has already been sent using the `requestSent` ref.
   * - Constructs an object `generationObject` containing the website's necessary information such as name, theme, logo details, and shop contact information.
   * - Sends an asynchronous POST request using Axios to the backend URL defined in the environment variables to trigger website generation.
   * - Logs the response and handles success or error outcomes accordingly.
   * - On success, clears local storage, starts checking the generation status, and marks the request as sent.
   * - On failure, logs the error and handles the error message display.
   *
   * Dependencies:
   * - `requestSent`: Ref object to ensure the request is sent only once.
   * - `nameWebsite`, `themeWebsite`, `urlLogoWebsite`, `infoLogoWebsite`, `colorLogoWebsite`: Information used to create the website.
   * - `shopEmail`, `shopPhone`: Shop contact information.
   * - `setErrorMsg`: State function to set an error message if the request fails.
   * - `setStepValid`: State function to set the step as invalid if the request fails.
   * - `clearLocalStorage`: Function to clear specific local storage items.
   * - `checkGenerationStatus`: Function to periodically check the status of website generation.
   */
  const sendGenerationRequest = useCallback(async () => {
    if (requestSent.current) {
      console.log('Request already sent, skipping.');
      return;
    }

    console.log("Sending generation request...");

    const generationObject = {
      name: nameWebsite,
      theme: themeWebsite,
      url: urlLogoWebsite,
      description: infoLogoWebsite,
      color: colorLogoWebsite,
      infoshop: {
        shopEmail: shopEmail,
        shopPhone: shopPhone
      },
      social: {
        linkFacebook: "https://www.facebook.com/",
        linkInsta: "https://www.instagram.com/",
        linkTwitter: "https://twitter.com/"
      }
    };

    try {
      const response = await axios.post(`${env.REACT_APP_BACK_URL}/webgen`, generationObject, { withCredentials: true });

      if (response.data.response !== 'ok') {
        console.log("Error from server : ",response.data.response);
        setErrorMsg(response.data.response);
        setStepValid(false);
        setThrowError(true);
        handleStep5Error(response.data.response);
      } else {
        console.log("Generation request sent successfully.Generation starts");
        //clearLocalStorage();
        checkGenerationStatus();
        requestSent.current = true;
      }
    } catch (error) {
      console.error('Error sending generation request:', error);
      handleStep5Error('Error sending generation request');
    }
  }, [nameWebsite, themeWebsite, urlLogoWebsite, infoLogoWebsite, colorLogoWebsite, setErrorMsg, setStepValid, shopEmail, shopPhone, checkGenerationStatus, setThrowError, handleStep5Error]);

  /**
   * A useMemo hook that generates an array of informational messages about the website generation process.
   * 
   * The messages provide brief updates and assurances regarding different aspects of the website generation,
   * such as SEO-friendliness, responsiveness, free hosting, and other features.
   * 
   * The final message uses the provided `nameWebsite` to dynamically generate the anticipated URL of the website.
   * If `nameWebsite` is not provided or is an empty string, it defaults to "yourprojectname".
   *
   * Dependencies:
   * - `nameWebsite`: The name of the website which is used to construct the URL in the final message.
   */
  const msgInfos = useMemo(() => [
    'Welcome to Weebseat! Your site is being crafted.',
    'Your website is on its way – just a moment!',
    'Hold tight, your vision is coming to life!',
    'Tired of waiting? Close this page by closing the tab and wait for the confirmation email.',
    'In 3-6 minutes, you will receive a one-page website.',
    'Your website will be designed to be responsive and SEO-friendly.',
    'We are designing your site with your description in mind!',
    'Your new website will harness the power of advanced SEO to attract visitors.',
    'Just a moment, we are brewing some top-notch SEO spells for your site!',
    'All-in-one service: hosting and domain included.',
    'Hold tight, your all-in-one website is being assembled with care!',
    `The address of your website will be: ${nameWebsite !== '' ? nameWebsite.replaceAll(' ', '').replaceAll('/', '').replaceAll('\\', '').toLowerCase() : 'yourprojectname'}.weebseat.com`,
    'Please be patient, you will enjoy your all-in-one website shortly.',
    'Tired of waiting? Close this page by closing the tab and wait for the confirmation email.',
    'The design of your site will be tailored to your specific needs and preferences.',
    'Generating engaging content – because your visitors deserve the best!',
    'SEO magic in progress – soon, your site will charm the search engines!',
    'Tired of waiting? Close this page by closing the tab and wait for the confirmation email.',
    'Activating AI power: your site will soon be live and kicking!',
    `Your site will be at ${nameWebsite !== '' ? nameWebsite.replaceAll(' ', '').replaceAll('/', '').replaceAll('\\', '').toLowerCase() : 'yourprojectname'}.weebseat.com.`,
    'Just grabbing some digital coffee – your site will be up in a jiffy!',
    'AI elves are hard at work – your stunning website is almost ready!',
    'Hang tight, we’re just adding the final touches to your responsive design!',
    'Tired of waiting? Close this page by closing the tab and wait for the confirmation email.',
    'We promise it’s worth the wait – your website is coming to life soon!',
    'Your site is in good hands – AI magic is happening right now!'
  ], [nameWebsite]);


  /**
   * useEffect hook to manage user login status and initiate the generation request.
   * 
   * This effect performs the following actions:
   * - Checks if the user is logged in by evaluating `loggedStatus`.
   * - If the user is not logged in, logs a message to the console and redirects the user to the login page.
   * - If the user is logged in, logs a message indicating successful mounting of Step5 and initiates the generation request.
   * 
   * Dependencies:
   * - `loggedStatus`: A boolean indicating if the user is logged in.
   * - `sendGenerationRequest`: Function to send a request to generate the website.
   * - `navigate`: Function to navigate to different routes.
   */
  useEffect(() => {
    if (!loggedStatus) {
      console.log('User not logged in, redirecting to login page.');
      navigate('/signin');
      return;
    }
    console.log("Step5 mounted and logged in.");
    sendGenerationRequest();
  }, [loggedStatus, sendGenerationRequest, navigate]);

  /**
   * useEffect hook to update the displayed message periodically.
   * 
   * This effect performs the following actions:
   * - Defines an `updateMessage` function that picks a random message from the `msgInfos` array.
   * - Resets the `fadeClass` to ensure a reflow is triggered for CSS animations.
   * - Sets a delay before updating the current message and applying the `fade-in-up` class to animate the message transition.
   *
   * Dependencies:
   * - `msgInfos`: Array of informational messages to be displayed.
   * - `setFadeClass`: State function to set the CSS class for the fade effect.
   * - `setCurrentMessage`: State function to update the currently displayed message.
   */
  useEffect(() => {
    const updateMessage = () => {
      const randomIndex = Math.floor(Math.random() * msgInfos.length);
      setFadeClass(''); // Reset the class to trigger reflow
      setTimeout(() => {
        setCurrentMessage(msgInfos[randomIndex]);
        setFadeClass('fade-in-up');
      }, 10); // Slight delay to ensure the reflow is applied
    };

    const intervalId = setInterval(updateMessage, 7000);
 
  /**
   * Cleanup function to clear the interval when the component unmounts or dependencies update.
   * 
   * This function ensures that when the component is unmounted or any dependency changes, 
   * the previously set interval to update messages is cleared. This prevents potential 
   * memory leaks and ensures the interval does not continue running in the background.
   * 
   * Dependencies:
   * - `msgInfos`: Array of informational messages, triggering the cleanup when it changes.
   */
  return () => clearInterval(intervalId);
  }, [msgInfos]);




  return (
    <div className="step5">

      <h1 className={fadeClass}>{currentMessage}</h1>
      <div className="img-box">
        <img className="circle large" src={largeCircle} alt="" />
        <img className="circle medium" src={mediumCircle} alt="" />
        <img className="circle small" src={smallCircle} alt="" />
        <div className="dots">
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
      </div>
      <div className='progress-height'>
        {percentage > 0 ?
          (<Progress 
            className="prog" 
            percent={percentage} 
            strokeColor={twoColors}
          />) : ('')
        }
      </div>
      <RoadMap stepsCompleted={step} goToStep={goToStep} />
    </div>
  );
};
