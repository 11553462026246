// CorrectorModal.jsx
import React from 'react';
import './CorrectorModal.css';

const CorrectorModal = ({ isOpen, title, message, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="corrector-modal-overlay">
      <div className="corrector-modal">
        <div className="corrector-modal-header">
          <h2>{title}</h2>
          <button className="corrector-modal-close" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="corrector-modal-body">
          <p>{message}</p>
        </div>
        <div className="corrector-modal-footer">
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default CorrectorModal;
