import React, { useEffect, useState } from 'react';
import { useNavigate  } from 'react-router-dom';
import fb from '../../assets/img/fb2.svg';
import x from '../../assets/img/x.png';
import { SocialIcon } from 'react-social-icons';
import { Button, Switch, message, Dropdown, Space, Mentions, ConfigProvider,Modal, Tooltip } from 'antd';
import { DownOutlined, PlusOutlined, DeleteOutlined, QuestionCircleOutlined, ExportOutlined } from '@ant-design/icons';
import Sharing from '../Share/Sharing';
import SubscriptionStatus from '../SubscriptionStatus/SubscriptionStatus';
import SubscriptionModal from '../SubscriptionModal/SubscriptionModal';
//import env from 'react-dotenv';
import './MainDash.css';
import { trackEvent } from '../../analytics';


/**
 * Returns a formatted date string representing the current date.
 * The format is "DD MMM" where DD is the day of the month,
 * and MMM is the abbreviated month name.
 *
 * @returns {string} The formatted date string.
 */
const getDate = () => {
    const today = new Date().toString().split(' ');
    return `${today[2]} ${today[1]}`;
};

/**
 * Formats a date string into a specific format "DD/MM/YYYY, HH:MM:SS".
 * 
 * This function takes a datetime string, converts it into a JavaScript Date object,
 * and then formats it into a string with the format "DD/MM/YYYY, HH:MM:SS".
 * 
 * @param {string} datetime - The date string to format.
 * @returns {string} - The formatted date string.
 */
const formatDate = (datetime) => {
    const date = new Date(datetime);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
};

const MainDash = ({ userInfos }) => {
    let navigate = useNavigate()

    const [currentDate] = useState(getDate());
    const [publishedDate, setPublishedDate] = useState(null);
    const [currentSitename, setCurrentSitename] = useState('');
    const [imageVersion,setImageVersion] = useState(0);
    const [wPreview, setWPreview] = useState('');
    const [checked, setChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [lastSite, setLastSite] = useState(null);
    const [wDir, setWDir] = useState('');

    // Social media related states
    const [items, setItems] = useState([]);
    const [links, setLinks] = useState([]);
    const [chooseProfile, setChooseProfile] = useState('Social profile');
    const [customerSocialLink, setCustomerSocialLink] = useState('');
    const [profileLink, setProfileLink] = useState('');
    const [goodSLink, setGoodSLink] = useState(false);
    const [socialLoading, setSocialLoading] = useState(false);
    const [countdown, setCountdown] = useState();
    const [isSubValid, setIsSubValid] = useState(false);
    const [subValidDate,setSubValidDate] = useState('');
    const [invalidWhy, setInvalidWhy] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false); // État pour gérer la visibilité de la modale
    const [nextBillingDate, setNextBillingDate] = useState('');
   
    // REGENERATE
    const [isRegenerating, setIsRegenerating] = useState(false)
    const [availableCredits, setAvailableCredits] = useState(0);



    // Fetching the trial period from the environment variable
    const trialDays = parseInt(process.env.REACT_APP_TRIAL_PERIOD_DAYS || 0);

    /**
     * useEffect hook to check the user's subscription status.
     * 
     * This effect runs when the component is mounted and performs the following steps:
     * 1. Sends a GET request to the /sub_valid endpoint to check the subscription validity.
     * 2. If the response indicates that the subscription is valid, updates the subscription
     *    expiration date and sets the 'isSubValid' state to true.
     * 3. If the subscription is not valid or any error occurs, sets the 'isSubValid' state to false.
     * 
     * Dependencies: none
     */
    useEffect(() => {
        let subscriptionCheckInterval;
        // Vérifier l'abonnement de l'utilisateur
        const checkSubscription = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACK_URL}/sub_valid`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                console.log('retour requete:', data)
                if (data.valid) {
                    //console.log(data.infos)
                    const expi = new Date(data.infos.current_period_end).toLocaleString('en-GB').split(',')[0]
                    const myNextBillingDate = new Date(data.infos.next_billing_time).toLocaleString('en-GB').split(',')[0]

                    setSubValidDate(expi)
                    setIsSubValid(true);
                    setAvailableCredits(data.infos.regen_credits);// credits restant
                    setNextBillingDate(myNextBillingDate)

                    // Calcul du compte à rebours de la période d'essai
                    const trialEndDate = new Date(data.infos.created_at);
                    trialEndDate.setDate(trialEndDate.getDate() + trialDays);
                    const remainingTrialDays = Math.ceil((trialEndDate - new Date()) / (1000 * 60 * 60 * 24));
                    //console.log(remainingTrialDays > 0 ? remainingTrialDays : 0)
                    setCountdown(remainingTrialDays > 0 ? remainingTrialDays : 0);
                        
                    // Si l'abonnement est valide, on arrête l'intervalle
                    clearInterval(subscriptionCheckInterval);
                    // Si des crédits sont disponibles, activer le bouton de régénération
                    if (data.infos.regen_credits > 0) {
                        setIsRegenerating(false); // Activer le bouton de régénération
                    }
                    
                } else {
                    setIsSubValid(false);
                    setInvalidWhy(data.reason)
                    setChecked(false)
                }
            } catch (error) {
                console.error('Error checking subscription:', error);
                setIsSubValid(false);
            }
        };
        // Appeler la vérification de l'abonnement à l'initialisation du composant
        checkSubscription();

        // Si l'abonnement est en attente, vérifier toutes les 30 secondes
        if (!isSubValid && invalidWhy === 'pending') {
            subscriptionCheckInterval = setInterval(() => {
                checkSubscription();
            }, 30000); // Toutes les 30 secondes
        }

        // Nettoyage de l'intervalle à la destruction du composant
        return () => clearInterval(subscriptionCheckInterval);

    }, [isSubValid, invalidWhy, trialDays,setChecked]);


    /**
     * useEffect hook to handle the setup and state updates related to the user's last site generation.
     *
     * This effect runs whenever the 'userInfos' or 'imageVersion' dependencies change and performs the following steps:
     * 1. Defines a function 'daysUntilJPlus14' that calculates the number of days until a date that is 14 days after the given date.
     * 2. If the user has generation data, retrieves the most recent generation information (last site).
     * 3. Sets various states based on the information about the last site, including:
     *    - 'lastSite' with the latest site information.
     *    - 'publishedDate' to a formatted date string if the site is published, otherwise sets it to 'Not published'.
     *    - 'currentSitename' with the site's name.
     *    - 'wPreview' with the preview URL of the site.
     *    - 'checked' to true or false based on the published status of the site.
     *    - 'wDir' with the site's directory.
     *    - 'countdown' with the number of days remaining until 14 days after the site generation date.
     *
     * @param {Object} userInfos - An object containing user information and generation data.
     * @param {number} imageVersion - The version number of the image for cache-busting purposes.
     */
    useEffect(() => {
        /*
        function daysUntilJPlus14(dateString) {
            const inputDate = new Date(dateString);
            const futureDate = new Date(inputDate);
            futureDate.setDate(inputDate.getDate() + 14);
            const today = new Date();
            const timeDifference = futureDate - today;
            const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
            if(daysDifference > 0){
                return daysDifference;
            }else{
                return 0
            }
        }
        */
        // On récupère le dernier site fait
        if (userInfos.generation && userInfos.generation.length > 0) {
            const lastSiteTemp = Array.from(userInfos.generation)[0];
            setLastSite(lastSiteTemp);
            lastSiteTemp.published === 1 ? setPublishedDate(formatDate(lastSiteTemp.date_publish)) : setPublishedDate('Not published');
            setCurrentSitename(lastSiteTemp.w_name);
            setWPreview(`${process.env.REACT_APP_BACK_URL}/${lastSiteTemp.w_preview}?v=${imageVersion}`);
            lastSiteTemp.published === 1 ? setChecked(true) : setChecked(false);
            setWDir(lastSiteTemp.w_directory);
           // setCountdown(daysUntilJPlus14(lastSiteTemp.date_gen));
        }
    }, [userInfos, imageVersion]);


    /**
     * useEffect hook to set up social media links.
     *
     * This effect runs whenever `lastSite` changes and initializes two state arrays: 
     * `newItems` and `newLinks` based on the presence of social media links in `lastSite`.
     * 
     * Steps:
     * 1. Initializes empty arrays `newItems` and `newLinks`.
     * 2. Checks the existence of `lastSite`.
     * 3. Based on the presence of social media links (Facebook, Instagram, X) in `lastSite`, 
     *    it populates:
     *    - `newItems` with objects containing social icons and labels for missing social links.
     *    - `newLinks` with objects containing social icons, labels, and hrefs for existing social links.
     * 4. Updates state variables `items` and `links` with `newItems` and `newLinks` respectively.
     *
     * Dependencies:
     * - `lastSite`: The latest site information object which includes details about social media links.
     */
    useEffect(() => {
        const newItems = [];
        const newLinks = [];
        if (lastSite) {
            if (!lastSite.fb_site) {
                newItems.push({ icon: <SocialIcon style={{ width: "20px", height: "20px" }} network="facebook" />, label: 'Facebook', key: '1' });
            } else {
                newLinks.push({ icon: <SocialIcon style={{ width: "20px", height: "20px" }} href={lastSite.fb_site} network="facebook" />, label: 'Facebook', link: lastSite.fb_site, key: '2' });
            }
            if (!lastSite.insta_site) {
                newItems.push({ icon: <SocialIcon style={{ width: "20px", height: "20px" }} network="instagram" />, label: 'Instagram', key: '3' });
            } else {
                newLinks.push({ icon: <SocialIcon style={{ width: "20px", height: "20px" }} href={lastSite.insta_site} network="instagram" />, label: 'Instagram', link: lastSite.insta_site, key: '4' });
            }
            if (!lastSite.x_site) {
                newItems.push({ icon: <SocialIcon style={{ width: "20px", height: "20px" }} network="x" />, label: 'X', key: '5' });
            } else {
                newLinks.push({ icon: <SocialIcon style={{ width: "20px", height: "20px" }} href={lastSite.x_site} network="x" />, label: 'X', link: lastSite.x_site, key: '6' });
            }
            setItems(newItems);
            setLinks(newLinks);
        }
    }, [lastSite]);


    const handlePreviewClick = (e) => {
        // Si revision_credit > 0, rediriger vers /revisor (même sans ouvrir de nouvel onglet)
        if (lastSite && lastSite.revision_credit > 0) {
          e.preventDefault();
          navigate('/revisor');
        }
      };
    /**
     * Handles the publishing and unpublishing of a site.
     *
     * This function sends an asynchronous POST request to the server to update the publication state of the site.
     * It manages the loading state and provides user feedback based on the server's response.
     *
     * Steps:
     * 1. Sets the loading state to true to indicate the start of an operation.
     * 2. Sends a POST request to the server with the publication state and site ID.
     * 3. Checks the response status:
     *    - If the response is not ok, throws an error.
     * 4. Parses the response data and updates the loading state.
     * 5. Based on the server's response, updates the state variables:
     *    - If the site is activated, updates the `checked` state to true and sets `publishedDate` to the published date.
     *    - If the site is deactivated, updates the `checked` state to false and sets `publishedDate` to 'Not published'.
     *    - Displays a success, warning, or error message based on the server's response.
     *
     * @param {boolean} state - The desired publication state (true for publish, false for unpublish).
     * @returns {Promise<void>}
     */
    const handlePublish = async (state) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACK_URL}/publication`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ state, id_gen: parseInt(lastSite.id_gen) }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setIsLoading(false);
            if (data.response === 'ACTIVATED') {
                setChecked(true);
                setPublishedDate(new Date(data.publishedOn).toLocaleString('en-GB'));
                message.info(data.message);
            } else if (data.response === 'DESACTIVATED') {
                setChecked(false);
                setPublishedDate('Not published');
                message.warning(data.message);
            } else {
                message.error(data.message);
            }
        } catch (error) {
            console.error('Error in POST request:', error);
            
        }
    };



  /*****************REGENERATION *****************/
  /*
  const handleRegenerate = async (id_gen) => {
    setIsRegenerating(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACK_URL}/regenerate`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id_gen }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const endAjax = await response.json();
      if (endAjax.regenerated) {
        setIsRegenerating(false);
        setImageVersion(prevVersion => prevVersion + 1); // Incrémenter la version de l'image
        message.info('Regeneration successful');
      }
    } catch (error) {
      message.error('An error occurred during regeneration');
      console.error('Error in regeneration request:', error);
      setIsRegenerating(false);
    }
  };
*/
const handleRegenerate = async (id_gen) => {
    // Afficher la modal de confirmation
    Modal.confirm({
      title: (<div className='mod-title'>Warning: Full Site Regeneration</div>),
      content: (
      <div className='mod-txt'>
        <br/>
        <p>You are about to completely regenerate your website using Weebseat's AI technology.</p> 
        <p><strong>Please note: </strong> This process will erase all current content on your website and replace it with a new AI-generated version.</p>
        <p>The process could take up to 3 minutes.</p> 
        <p>Each regeneration will cost 1 credit.</p>
        <p>Do you wish to regenerate?</p> 
      </div>
      ),
      width:700,
      okText: 'Regeneration' ,
      cancelText: 'Cancel',
      okButtonProps: {
        style: {
          backgroundColor: '#E16400', // Couleur de fond personnalisée
          borderColor: '#E16400',     // Couleur de bordure
          borderRadius: '8px',     // Rayon de bordure
          fontSize: '18px',        // Taille de police
          fontWeight: 700,     
          height:'40px',
               // Poids de la police
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: '#f0f0f0', // Couleur de fond pour le bouton annuler
          borderColor: '#d9d9d9',     // Couleur de bordure
          borderRadius: '8px',     // Rayon de bordure
          fontSize: '18px',        // Taille de police
          fontWeight: 500,
          height:'40px',
          colorPrimaryHover: '#E16400',              // Poids de la police
        },
      },
      onOk: async () => {
        // Exécuter la régénération si l'utilisateur confirme
        setIsRegenerating(true);
       
        try {
          const response = await fetch(`${process.env.REACT_APP_BACK_URL}/regenerate`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id_gen }),
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const endAjax = await response.json();
          console.log('endAjax', endAjax)
          if (endAjax.regenerated) {
            setImageVersion(prevVersion => prevVersion + 1); // Incrémenter la version de l'image
            setAvailableCredits(prevCredits => prevCredits - 1); // Déduire un crédit

            // Réinitialiser les réseaux sociaux dans la base
            await handleDelSocial('Facebook');
            await handleDelSocial('Instagram');
            await handleDelSocial('X');

                // Dépublication du site
            if (lastSite && lastSite.id_gen && checked ){
                await handlePublish(false);
            } else {
                console.error('id_gen is missing!');
            }
            setIsRegenerating(false);
            message.info('Regeneration successful');
          }else{
            message.error(endAjax.message);
            console.error('Error in regeneration request:', endAjax.message);
            setIsRegenerating(false);
    
          }
        } catch (error) {
          message.error('An error occurred during regeneration');
          console.error('Error in regeneration request:', error);
          setIsRegenerating(false);

        }
      },
      onCancel() {
        message.info('Regeneration canceled');
      },
    });
  };





    /**
     * Handles the menu click event to select a social profile.
     *
     * This function is triggered when a user clicks on an item in the menu.
     * It finds the selected social profile from the `items` array using the provided key
     * and updates the state variable `chooseProfile` with the label of the chosen social profile.
     *
     * Steps:
     * 1. Finds the selected social profile item in the `items` array based on the event key.
     * 2. Sets the `chooseProfile` state variable to the label of the selected profile.
     *
     * Dependencies:
     * - `items`: Array of menu items where each item represents a social profile.
     * - `setChooseProfile`: State setter function to update the chosen social profile.
     *
     * @param {Object} e - The event object triggered by the menu click.
     */
    const handleMenuClick = (e) => {
        const choosedSocial = items.find(item => item.key === e.key);
        setChooseProfile(choosedSocial.label);
    };

    /**
     * Validates the provided social media link.
     *
     * This function checks if the given URL matches the required patterns for Facebook, Instagram, or Twitter/X URLs.
     * It uses regular expressions to verify the format and sets the `goodSLink` state based on the validation result.
     *
     * Supported URL formats:
     * - Facebook: Should be in the format of "https://www.facebook.com/{username}" or variations.
     * - Instagram: Should be in the format of "https://www.instagram.com/{username}" or variations.
     * - Twitter/X: Should be in the format of "https://www.twitter.com/{username}", "https://twitter.com/{username}", 
     * or corresponding variations for X.
     *
     * Steps:
     * 1. Defines regular expressions for Facebook, Instagram, and Twitter/X URL formats.
     * 2. Tests the provided URL against these regular expressions.
     * 3. Updates the `goodSLink` state to true if the URL matches any of the patterns, otherwise false.
     *
     * @param {string} url - The social media URL to validate.
     */
    const validateLink = (url) => {
        const facebookRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.?]+/;
        const instagramRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9.?]+/;
        const twitterRegex = /^(https?:\/\/)?(www\.)?(twitter|x)\.com\/[a-zA-Z0-9.?]+/;
        setGoodSLink(facebookRegex.test(url) || instagramRegex.test(url) || twitterRegex.test(url));
    };

    /**
     * Handles the update of the customer's social link.
     *
     * This function takes a social media link as input, validates the link using the `validateLink` function,
     * and updates the state variable `customerSocialLink` with the provided link.
     *
     * Steps:
     * 1. Validates the provided social media link.
     * 2. Updates the `customerSocialLink` state with the validated link.
     *
     * Dependencies:
     * - `validateLink`: Function to validate the social media link.
     * - `setCustomerSocialLink`: State setter function to update the customer's social link.
     *
     * @param {string} theSocialLink - The social media link to handle and validate.
     */
    const handleCustomerSocialLink = (theSocialLink) => {
        validateLink(theSocialLink);
        setCustomerSocialLink(theSocialLink);
    };

    /**
     * Updates social link information via a POST request.
     *
     * This function sends a POST request to the server to update social link information.
     * It takes in an object containing data to be updated and posts it to the endpoint.
     * If the request is successful, the response data is returned. If an error occurs,
     * an error message is displayed.
     *
     * Steps:
     * 1. Sends a POST request to the server with the update data.
     * 2. Checks the response status:
     *    - If the response is not ok, throws an error.
     * 3. Parses and returns the response data if the request is successful.
     * 4. If an error occurs during the fetch operation, catches the error
     *    and displays an error message.
     *
     * Dependencies:
     * - `process.env.REACT_APP_BACK_URL`: Base URL of the server.
     * - `message`: Object for displaying error messages.
     *
     * @param {Object} updateDatas - The data to update, including social link information.
     * @returns {Promise<Object|undefined>} The response data if successful, otherwise undefined.
     */
    const fetchUpdateSocial = async (updateDatas) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACK_URL}/addsocial`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateDatas),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return await response.json();
        } catch (error) {
            message.error('An error occurs when updating social link');
        }
    };

    /**
     * Handles adding a social link for a customer.
     *
     * This function is responsible for adding a social media link based on the selected social profile
     * (Facebook, Instagram, or X) and the provided customer social link. It validates the link and sends
     * a POST request to update the social link information. If successful, it updates the state variables
     * to reflect the added link and provides feedback via messages.
     *
     * Steps:
     * 1. Sets the loading state to true to indicate the operation is in progress.
     * 2. Determines the social profile and prepares the data object based on the selected profile and customer link.
     * 3. Adds the site ID (id_gen) to the data object.
     * 4. If the link is valid (`goodSLink` is true):
     *    - Checks if the link is not empty.
     *    - Sends a POST request with the data to update the social link.
     *    - If the response indicates success:
     *      - Updates the UI by removing the profile from the `items` array and adding it to the `links` array.
     *      - Resets the selected profile and customer link state.
     * 5. Provides feedback via messages for success or error situations.
     *
     * Dependencies:
     * - `fetchUpdateSocial`: Function to make the POST request to update social link information.
     * - `message`: Object for displaying feedback messages.
     * - Various state variables and setters (`setSocialLoading`, `setItems`, `setLinks`, etc.).
     *
     * @param {Object} e - The event object triggered by the add social link action.
     */
    const handleAddSocial = async (e) => {
        setSocialLoading(true);
        let mySocialLink;
        switch (chooseProfile) {
            case 'Facebook':
                mySocialLink = { netname: 'Facebook', network: 'fb_site', link: customerSocialLink };
                break;
            case 'Instagram':
                mySocialLink = { netname: 'Instagram', network: 'insta_site', link: customerSocialLink };
                break;
            case 'X':
                mySocialLink = { netname: 'X', network: 'x_site', link: customerSocialLink };
                break;
            default:
                mySocialLink = { link: false };
                break;
        }
        mySocialLink.id_gen = lastSite.id_gen;
        if (goodSLink) {
            if (mySocialLink.link) {
                const data = await fetchUpdateSocial(mySocialLink);
                if (data.success) {
                    setSocialLoading(false);
                    const index = items.findIndex(obj => obj.label === mySocialLink.netname);
                    if (index !== -1) {
                        const newItems = [...items];
                        const [obj] = newItems.splice(index, 1);
                        obj.link = mySocialLink.link;
                        setItems(newItems);
                        setLinks([...links, obj]);
                        message.info('Social link added successfully!');
                        setChooseProfile('Social profile');
                        setCustomerSocialLink('');
                    }
                }
            } else {
                message.error('No profile link provided');
                setSocialLoading(false);
            }
        } else {
            message.error('Social Link not accepted');
            setSocialLoading(false);
        }
    };

    /**
     * Handles the deletion of a social link for a specific social network.
     *
     * This function performs the following steps:
     * 1. Determines the social network and prepares the data object based on the social network name.
     * 2. Adds the site ID (`id_gen`) to the data object.
     * 3. If a link is provided:
     *    - Sends a POST request with the data to update (effectively delete) the social link.
     *    - If the response indicates success:
     *      - Updates the UI by removing the profile from the `links` array and adding it back to the `items` array.
     *      - Resets the selected profile.
     *    - Provides feedback via success or error messages.
     *
     * Dependencies:
     * - `fetchUpdateSocial`: Function to make the POST request to update social link information.
     * - `message`: Object for displaying feedback messages.
     * - Various state variables and setters (`setItems`, `setLinks`, etc.).
     *
     * @param {string} SocialNetwork - The name of the social network to delete (e.g., 'Facebook', 'Instagram', 'X').
     */
    const handleDelSocial = async (SocialNetwork) => {
        let mySocialLink;
        switch (SocialNetwork) {
            case 'Facebook':
                mySocialLink = { netname: 'Facebook', network: 'fb_site', link: 'https://www.facebook.com/' };
                break;
            case 'Instagram':
                mySocialLink = { netname: 'Instagram', network: 'insta_site', link: 'https://www.instagram.com/' };
                break;
            case 'X':
                mySocialLink = { netname: 'X', network: 'x_site', link: 'https://twitter.com/home' };
                break;
            default:
                mySocialLink = { link: false };
                break;
        }
        mySocialLink.id_gen = lastSite.id_gen;
        if (mySocialLink.link) {
            const data = await fetchUpdateSocial(mySocialLink);
            if (data.success) {
                const index = links.findIndex(link => link.label === mySocialLink.netname);
                if (index !== -1) {
                    const newLinks = [...links];
                    const [removedLink] = newLinks.splice(index, 1);
                    const { link, ...rest } = removedLink;
                    setItems([...items, rest]);
                    setLinks(newLinks);
                    message.info('Social link removed successfully!');
                    setChooseProfile('Social profile');
                }
            } else {
                message.error('No profile link provided');
            }
        }
    };

/**
 * useEffect hook that updates the profile link placeholder based on the selected social profile.
 *
 * This effect runs whenever the 'chooseProfile' state changes. If 'chooseProfile' is not 'Social profile',
 * it sets a default profile link placeholder according to the selected social network:
 * - 'Facebook': 'https://www.facebook.com/profile.php?id=weebseat'
 * - 'Instagram': 'https://www.instagram.com/weebseat'
 * - 'X': 'https://twitter.com/weebseat'
 *
 * If 'chooseProfile' matches any of these cases, the corresponding profile link is set using `setProfileLink`.
 * If 'chooseProfile' does not match any of these cases, an empty string is set as the profile link.
 *
 * Dependencies:
 * - `chooseProfile`: State variable that determines which social profile is currently selected.
 * - `setProfileLink`: State setter function to update the profile link placeholder.
 */
useEffect(() => {
    if (chooseProfile !== 'Social profile') {
        switch (chooseProfile) {
            case 'Facebook':
                setProfileLink('https://www.facebook.com/profile.php?id=weebseat');
                break;
            case 'Instagram':
                setProfileLink('https://www.instagram.com/weebseat');
                break;
            case 'X':
                setProfileLink('https://twitter.com/weebseat');
                break;
            default:
                setProfileLink('');
                break;
        }
    }
    }, [chooseProfile]);

    /**
     * Properties for the dropdown menu component.
     *
     * The `menuProps` object includes:
     * - `items`: An array containing the menu items to be displayed in the dropdown.
     * - `onClick`: A callback function to handle click events on the menu items.
     */
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    /**
     * Handles the access control for various features based on the user's subscription status.
     *
     * If the subscription is valid, the provided feature function will be called.
     * If the subscription is not valid, a modal will be displayed to the user.
     *
     * Dependencies:
     * - `isSubValid`: Boolean indicating the validity of the user's subscription.
     * - `setIsModalVisible`: Function to set the visibility of the modal.
     *
     * @param {Function} featureFunction - The function representing the feature to be accessed.
     */
    const handleFeatureAccess = (featureFunction) => {
        if (isSubValid) {
            featureFunction();
        } else {
            setIsModalVisible(true);
        }
    };
/*
    const handleCreateSub = async (e) => {
        e.preventDefault();
        if (!isSubValid) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACK_URL}/activefreesub`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include', // Inclusion des informations d'authentification
                    body: JSON.stringify({ plan: 'FREE90' }),
                });
                if (!response.ok) {
                    throw new Error('Failed to activate subscription');
                }
                const result = await response.json();
                if(result.subscription === 'created'){
                    //
                    Modal.success({
                        title: 'You are lucky!',
                        content: 'Weebseat offer to you a free subscription for 90 days!',
                        onOk: () => {
                            window.location.reload(); // Rafraîchir la page après appui sur OK
                        },
                    });
                }else if(result.subscription){
                    message.info(result.message);
                }else if(!result.subscription){
                    message.error(result.message);
                }
            } catch (error) {
                console.error('Error activating subscription:', error);
                message.error('Impossible to create subscription!');
            }
        }
    }
*/
/*
    const handleCreateSub = async (e) => {
        console.log('sub',e)
        e.preventDefault();
        if (!isSubValid) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACK_URL}/create_sub`, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    credentials: 'include', 
                    body: JSON.stringify({ plan: 'PLAN_A' }),
                });
                if (!response.ok) {
                    throw new Error('Failed to activate subscription');
                }
                const result = await response.json();
                if(result.subscription === 'created'){
                    //Abonnement créé, succés
                    Modal.success({
                        title: 'Subscription successful!',
                        content: 'Welcome aboard. Your subsciption has bee approuved',
                        onOk: () => {window.location.reload()},
                    });
                }else if(result.subscription){
                    message.info(result.message);
                }else if(!result.subscription){
                    message.error(result.message);
                }
            } catch (error) {
                console.error('Error activating subscription:', error);
                message.error('Impossible to create subscription!');
            }
        }
    }
*/

const handleCloseAndRedirect = () => {
    trackEvent('SEE PLANS', `SEE PLANS BUTTON`, 'User click on SEE PLANS in popup', 1);
    setIsModalVisible(false)
    navigate('/subscription')
}
const handleCancel = () => {
    trackEvent('CANCEL SEE PLANS', `CANCEL BUTTON`, 'User click on CROSS in SEE PLANS popup', 1);
    setIsModalVisible(false)
}

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: `#E16400`,
                        colorPrimaryHover: `#E16400`,
                        colorPrimaryActive: `#E16400`,
                        lineWidth: 1,
                        defaultBg: `#EDF2F6`,
                        controlHeight: 60,
                        borderRadius: 9.38,
                        defaultBorderColor: `#E0E4EC`,
                        fontSize: 19.26,
                        fontFamily: `gotham pro`,
                        fontWeight: 500,
                    },
                    Mentions: {
                        colorPrimary: `#E16400`,
                        colorPrimaryHover: `#E16400`,
                        colorPrimaryActive: `#E16400`,
                        lineWidth: 1,
                        fontSize: '20px',
                        padding:'5px',
                        autoSize:true,
                        
                    },
                    Switch: {
                        colorPrimary: `#E16400`,
                        colorPrimaryHover: `#E16400`,
                        colorPrimaryActive: `#E16400`,
                        lineWidth: 1,
                    }
                },
            }}
        >
            <div className="main">
                <h1>{currentSitename}</h1>
                <p>{currentDate}</p>
                <div className="website-section">
                    <div className="input-parent2">
                        <div className="input-box">
                            <p>Publish Date</p>
                            <div className="text-box">
                                <p>{publishedDate}</p>
                            </div>
                        </div>
                        <div className="input-box">
                            <p>Domain Name</p>
                            <div className="text-box">
                                <p>{`${currentSitename.split(' ').join('').toLowerCase()}.weebseat.com`}</p>
                            </div>
                        </div>
                        <div className="input-box">
                            <p>Publishing</p>
                            <div className="check-box">
                                <p>Publish</p>
                                <Switch
                                    loading={isLoading}
                                    checked={checked}
                                    onChange={() => handleFeatureAccess(() => handlePublish(!checked))}
                                />
                            </div>
                        </div>
                        <div className="input-box">
                            <p>Share On Social</p>
                            <div className="link-box">
                                {checked ? (
                                    <Sharing directory={currentSitename} />
                                ) : (
                                    <>
                                        <img src={fb} alt="Facebook" />
                                        <img width="21" src={x} alt="twitter" />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="input-box">
                            <p>
                                <Tooltip title={ availableCredits <= 1 ? 'You have '+availableCredits+' credit.' : 'You have '+availableCredits+' credits.'}>
                                    Not happy? Let AI redesign it for you!
                                </Tooltip> 
                            </p>
                               
                            <Button
                                className="regeneration"
                                icon={<DeleteOutlined />}
                                loading={isRegenerating}
                                disabled={(availableCredits < 1)&& isSubValid}
                                onClick={() => handleFeatureAccess(() => {handleRegenerate(userInfos.generation[0].id_gen)})}
                            >
                                Rebuild
                            </Button>
                        </div>
                    </div>
                    <h2>View Live Website</h2>
                    {/* Social media links section */}
                    <div className="web-cont">
                        <a href={checked ? `https://${currentSitename}.weebseat.com` : `${process.env.REACT_APP_GENERATION}/${wDir}/index.html?v=${Date.now()}`} target="_blank" rel="noreferrer" onClick={handlePreviewClick}>
                            <div className="hover-cont">
                                <img className="mypreview" src={wPreview} alt="" />
                                <div className="hover-img">
                                    <div className="hover-img-text"><ExportOutlined /> Open my Weebseat</div>
                                </div>
                            </div>
                        </a>
                        <div className="social-mod">
                            <div className="info-title smaller">
                                Your Social Links: <a className="tuto-link" href="/faq?q=social"><Space>My social links</Space> <Space><QuestionCircleOutlined style={{ width: "20px", height: "20px" }} /></Space></a>
                            </div>

                            <div className={(items.length === 0) ? 'invisible2' : 'drop-cont'}>
                                <Dropdown menu={menuProps}>
                                    <Button className="social-btn">
                                        <Space>
                                            {chooseProfile !== 'Social profile' ? (<SocialIcon style={{ width: "20px", height: "20px" }} network={chooseProfile.toLowerCase()} />) : ''}
                                            {chooseProfile}
                                            <DownOutlined />
                                        </Space>
                                    </Button>
                                </Dropdown>
                                <div className={chooseProfile === 'Social profile' ? 'soc-inp invisible' : 'soc-inp'}>
                                    &nbsp;
                                    <Mentions  placeholder={profileLink} value={customerSocialLink} onChange={handleCustomerSocialLink} />
                                    &nbsp;
                                    <Space>
                                        <Button
                                            type="text"
                                            icon={<PlusOutlined style={{ color: '#CDCDCD' }} />}
                                            onClick={() => handleFeatureAccess(handleAddSocial)}
                                            loading={socialLoading}
                                        />
                                    </Space>
                                </div>
                            </div>

                            <div className="icons-with-links">
                                {links.map((link) => (
                                    <div className="icon-link" key={link.key}>
                                        {link.icon}
                                        <span className="txt-link" style={{ marginLeft: "5px", marginRight: "5px" }}>{link.link}</span>
                                        <Space>
                                            <Button
                                                type="text"
                                                icon={<DeleteOutlined style={{ color: '#CDCDCD' }} />}
                                                onClick={() => handleFeatureAccess(() => handleDelSocial(link.label))}
                                            />
                                        </Space>
                                        <br />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="payment-box">
                        <SubscriptionStatus isSubValid={isSubValid} subValidDate={subValidDate} countdown={countdown} invalidWhy={invalidWhy} nextBillingDate={nextBillingDate} />
                    </div>
                </div>
            </div>
            <SubscriptionModal visible={isModalVisible} onClose={handleCloseAndRedirect} onCancel={handleCancel}/>
        </ConfigProvider>
    );
};

export default MainDash;
